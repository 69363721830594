import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  dismissible: true,
  duration: 3000,
});

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

$axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 422) {
      var errors = error.response.data.errors
        ? error.response.data.errors
        : error.response.data;
      for (var key in errors) {
        var validation_error = errors[key];
        for (var message in validation_error) {
          toaster.error(validation_error[message]);
        }
      }
    } else if (error.response.status == 401 || error.response.status == 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      toaster.error(
        error.response.data.message +
          "<br> Anda Tidak Bisa Mengakses/Melakukan Aksi di Halaman Ini"
      );

      delete $axios.defaults.headers.Authorization;
      // router.push("/");
      setTimeout(function () {
        window.location.href = "/";
      }, 1500);
    }
  }
);
export default $axios;
