import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login Page",
    component: () => import("@/components/page/Auth/LoginPage"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/register",
    name: "Register Page",
    component: () => import("@/components/page/Auth/RegisterPage"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/dashboard",
    name: "Main Dashboard",
    component: () => import("@/components/page/Dashboard/MainDashboard"),
    meta: {
      layout: "landing_page",
    },
  },
  {
    path: "/version_control",
    name: "Version Control",
    component: () => import("@/components/page/VersionControl"),
  },
  {
    path: "/package",
    name: "List Paket",
    component: () => import("@/components/page/Package/Index"),
  },
  {
    path: "/package/add",
    name: "Tambah Paket",
    component: () => import("@/components/page/Package/Add"),
  },
  {
    path: "/package/:package_id",
    name: "Detail Paket",
    component: () => import("@/components/page/Package/Detail"),
  },
  {
    path: "/package/:package_id/edit",
    name: "Edit Paket",
    component: () => import("@/components/page/Package/Edit"),
  },
  {
    path: "/domain",
    name: "Domain",
    component: () => import("@/components/page/Domain"),
  },
  {
    path: "/setting",
    name: "Pengaturan Aplikasi",
    component: () => import("@/components/page/Setting"),
  },
  {
    path: "/client",
    name: "Managemen Client",
    component: () => import("@/components/page/Client/Index"),
  },
  {
    path: "/client/add",
    name: "Tambah Client",
    component: () => import("@/components/page/Client/Add"),
  },
  {
    path: "/client/:client_id",
    name: "Edit Client",
    component: () => import("@/components/page/Client/Edit"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/components/page/Auth/Profile"),
  },
  {
    path: "/transaction",
    name: "Transaksi",
    component: () => import("@/components/page/Transaction/Index"),
  },
  {
    path: "/transaction/:transaction_id",
    name: "Detail Transaksi",
    component: () => import("@/components/page/Transaction/Detail"),
  },
  {
    path: "/transaction/invoice/:transaction_id",
    name: "Invoice Transaksi",
    component: () => import("@/components/page/Transaction/Invoice"),
    meta: {
      layout: "print",
    },
  },
  {
    path: "/transaction/process/:package_id/:subscription_time",
    name: "Proses Transaksi",
    component: () => import("@/components/page/Transaction/Process"),
  },
  {
    path: "/service",
    name: "Service Aktif",
    component: () => import("@/components/page/Service/Index"),
  },
  {
    path: "/service/:service_id",
    name: "Detail Service",
    component: () => import("@/components/page/Service/Detail"),
  },
  {
    path: "/payment-account",
    name: "Akun Pembayaran",
    component: () => import("@/components/page/PaymentAccount"),
  },
  {
    path: "/voucher",
    name: "Voucher",
    component: () => import("@/components/page/Voucher/Index"),
  },
  {
    path: "/voucher/add",
    name: "Tambah Voucher",
    component: () => import("@/components/page/Voucher/Add"),
  },
  {
    path: "/voucher/:voucher_id",
    name: "Edit Voucher",
    component: () => import("@/components/page/Voucher/Edit"),
  },
  {
    path: "/statistic",
    name: "Statistik",
    component: () => import("@/components/page/Statistic/Index"),
  },
  {
    path: "/statistic/detail",
    name: "Statistik Detail",
    component: () => import("@/components/page/Statistic/Detail"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
