<template>
  <Auth v-if="!isLoggedIn || $route.meta.layout == 'auth'" />
  <Print v-else-if="isLoggedIn && $route.meta.layout == 'print'" />
  <MainLayout v-else-if="isLoggedIn && $route.meta.layout != 'print'" />
</template>

<script>
import MainLayout from "./components/page/Layout/MainLayout.vue";
import Auth from "./components/page/Layout/AuthLayout.vue";
import Print from "./components/page/Layout/PrintLayout.vue";

export default {
  name: "App",
  components: {
    MainLayout,
    Auth,
    Print,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
