import Vuex from "vuex";

import $axios from "./api";

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: localStorage.getItem("user") || {},
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = 1;
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      localStorage.removeItem("user");
      state.user = {};
    },
  },

  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        $axios
          .post("/login", user)
          .then((res) => {
            if (res && res.status == 200) {
              let token = res.data.token;
              let user = res.data.user;

              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));

              $axios.defaults.headers.Authorization = "Bearer " + token;

              commit("auth_success", {
                token,
                user,
              });

              resolve(res);
            }
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");

        $axios
          .post("/register", user)
          .then((res) => {
            if (res.status == 201) {
              let token = res.data.token;
              let user = res.data.user;

              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));

              $axios.defaults.headers.Authorization = "Bearer " + token;

              commit("auth_success", {
                token,
                user,
              });

              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");

        $axios.post("/logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        delete $axios.defaults.headers.Authorization;
        resolve();
      });
    },
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: () => JSON.parse(localStorage.getItem("user")),
  },
});
