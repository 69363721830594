<template>
  <div class="nk-sidebar-element nk-sidebar-body">
    <div class="nk-sidebar-content">
      <div class="nk-sidebar-menu" data-simplebar>
        <ul class="nk-menu">
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/' },
            ]"
          >
            <router-link to="/dashboard" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-dashboard-fill"></em
              ></span>
              <span class="nk-menu-text">Dashboard</span>
            </router-link>
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/client' },
            ]"
            v-if="user.role == 'admin'"
          >
            <router-link to="/client" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-users-fill"></em
              ></span>
              <span class="nk-menu-text">Client</span>
            </router-link>
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/version_control' },
            ]"
            v-if="user.role == 'admin'"
          >
            <router-link to="/version_control" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-tile-thumb"></em
              ></span>
              <span class="nk-menu-text">Version Control</span>
            </router-link>
          </li>
          <!-- .nk-menu-item -->
          <li
            :class="[
              'nk-menu-item has-sub',
              {
                active: $route.path == '/package' || $route.path == '/domain',
              },
            ]"
            @click="changeDisplayPackage()"
          >
            <a href="javascript:void(0)" class="nk-menu-link nk-menu-toggle">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-card-view"></em
              ></span>
              <span class="nk-menu-text">Paket/Domain</span>
            </a>
            <ul
              class="nk-menu-sub"
              :class="{
                'd-block':
                  displayPackage == 'd-block' ||
                  $route.path == '/package' ||
                  $route.path == '/domain',
                'd-none': displayPackage == 'd-none',
              }"
            >
              <li
                :class="[
                  'nk-menu-item ',
                  {
                    'active current-page': $route.path == '/package',
                  },
                ]"
              >
                <router-link to="/package" class="nk-menu-link"
                  ><span class="nk-menu-text">Paket</span></router-link
                >
              </li>
              <li
                class="nk-menu-item"
                :class="[
                  'nk-menu-item ',
                  {
                    'active current-page': $route.path == '/domain',
                  },
                ]"
              >
                <router-link to="/domain" class="nk-menu-link"
                  ><span class="nk-menu-text">Domain</span></router-link
                >
              </li>
            </ul>
            <!-- .nk-menu-sub -->
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/service' },
            ]"
          >
            <router-link to="/service" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-tranx"></em
              ></span>
              <span class="nk-menu-text">Service</span>
            </router-link>
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/voucher' },
            ]"
            v-if="user.role == 'admin'"
          >
            <router-link to="/voucher" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-card-view"></em
              ></span>
              <span class="nk-menu-text">Voucher</span>
            </router-link>
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/transaction' },
            ]"
          >
            <router-link to="/transaction" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-cart-fill"></em
              ></span>
              <span class="nk-menu-text">Transaksi</span>
            </router-link>
          </li>
          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/payment-account' },
            ]"
            v-if="user.role == 'admin'"
          >
            <router-link to="/payment-account" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-master-card"></em
              ></span>
              <span class="nk-menu-text">Akun Pembayaran</span>
            </router-link>
          </li>

          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/statistic' },
            ]"
          >
            <router-link to="/statistic" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-growth"></em
              ></span>
              <span class="nk-menu-text">Statistik</span>
            </router-link>
          </li>

          <li
            :class="[
              'nk-menu-item',
              { 'active current-page': $route.path == '/setting' },
            ]"
            v-if="user.role == 'admin'"
          >
            <router-link to="/setting" class="nk-menu-link">
              <span class="nk-menu-icon"
                ><em class="icon ni ni-setting-alt-fill"></em
              ></span>
              <span class="nk-menu-text">Pengaturan Aplikasi</span>
            </router-link>
          </li>
          <!-- .nk-menu-item -->
        </ul>
        <!-- .nk-menu -->
      </div>
      <!-- .nk-sidebar-menu -->
    </div>
    <!-- .nk-sidebar-content -->
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    displayPackage: "d-none",
  }),
  mounted() {},
  methods: {
    changeDisplayPackage() {
      this.displayPackage =
        this.displayPackage == "d-none" ? "d-block" : "d-none";
    },
  },
};
</script>
