<template>
  <div class="nk-content">
    <div class="container-fluid">
      <div class="nk-content-inner">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainLayout",
  components: {},
};
</script>
<style>
@import url("@/../public/assets/css/dashlite.css?ver=3.0.0");
</style>
