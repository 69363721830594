<template>
  <div class="nk-body bg-lighter npc-general has-sidebar p-0">
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- sidebar @s -->
        <div
          class="nk-sidebar nk-sidebar-fixed is-dark"
          id="sidebarButton"
          data-content="sidebarMenu"
        >
          <div class="nk-sidebar-element nk-sidebar-head">
            <div class="nk-menu-trigger">
              <a
                href="#"
                id="arrowLeft"
                class="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                data-target="sidebarMenu"
                ><em class="icon ni ni-arrow-left"></em
              ></a>
              <a
                href="#"
                class="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
                data-target="sidebarMenu"
                ><em class="icon ni ni-menu"></em
              ></a>
            </div>
            <div class="nk-sidebar-brand">
              <a href="javascript:void(0)" class="logo-link nk-sidebar-logo">
                <img
                  class="logo-light logo-img"
                  :src="setting.logo"
                  alt="logo"
                />
                <img
                  class="logo-dark logo-img"
                  :src="setting.logo"
                  alt="logo-dark"
                />
              </a>
            </div>
          </div>
          <!-- .nk-sidebar-element -->
          <SideBar />
          <!-- .nk-sidebar-element -->
        </div>
        <!-- sidebar @e -->
        <!-- wrap @s -->
        <div class="nk-wrap">
          <!-- main header @s -->
          <div class="nk-header nk-header-fixed is-light">
            <div class="container-fluid">
              <div class="nk-header-wrap">
                <div class="nk-menu-trigger d-xl-none ms-n1">
                  <a
                    href="#"
                    class="nk-nav-toggle nk-quick-nav-icon"
                    @click="toggleSidebar()"
                    data-target="sidebarMenu"
                    ><em class="icon ni ni-menu"></em
                  ></a>
                </div>
                <div class="nk-header-brand d-xl-none">
                  <a href="javascript:void(0)" class="logo-link">
                    <img
                      class="logo-light logo-img"
                      :src="setting.logo"
                      alt="logo"
                    />
                    <img
                      class="logo-dark logo-img"
                      :src="setting.logo"
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <!-- .nk-header-brand -->
                <div class="nk-header-news d-none d-xl-block">
                  <div class="nk-news-list">
                    <a class="nk-news-item" href="#">
                      <div class="nk-news-icon">
                        <em class="icon ni ni-card-view"></em>
                      </div>
                      <div class="nk-news-text" style="white-space: inherit">
                        <p>
                          Latest PortalGo Version : {{ version.version }}
                          <span> {{ version.description }}</span>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <!-- .nk-header-news -->
                <div class="nk-header-tools">
                  <ul class="nk-quick-nav">
                    <li class="dropdown user-dropdown">
                      <a
                        href="#"
                        class="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <div class="user-toggle">
                          <div class="user-avatar sm">
                            <img
                              :src="$store.getters.user.profile_picture_url"
                              alt=""
                            />
                          </div>
                          <div class="user-info d-none d-md-block">
                            <div class="user-status">
                              {{ $store.getters.user.role }}
                            </div>
                            <div class="user-name dropdown-indicator">
                              {{ $store.getters.user.name }}
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        class="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1"
                      >
                        <div
                          class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block p-4"
                        >
                          <div class="user-card">
                            <div class="user-avatar">
                              <img
                                :src="$store.getters.user.profile_picture_url"
                                alt=""
                              />
                            </div>
                            <div class="user-info">
                              <span class="lead-text">{{
                                $store.getters.user.name
                              }}</span>
                              <span class="sub-text">{{
                                $store.getters.user.email
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-inner">
                          <ul class="link-list">
                            <li>
                              <router-link to="/profile"
                                ><em class="icon ni ni-user-alt"></em
                                ><span>View Profile</span></router-link
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown-inner">
                          <ul class="link-list">
                            <li>
                              <a href="javascript:void(0)" @click="doLogout()"
                                ><em class="icon ni ni-signout"></em
                                ><span>Sign out</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- .nk-quick-nav -->
                </div>
                <!-- .nk-header-tools -->
              </div>
              <!-- .nk-header-wrap -->
            </div>
            <!-- .container-fliud -->
          </div>
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container-fluid">
              <div class="nk-content-inner">
                <div class="nk-content-body">
                  <div class="nk-block-head nk-block-head-sm">
                    <router-view></router-view>

                    <!-- .nk-block-between -->
                  </div>

                  <!-- .nk-block -->
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
          <!-- footer @s -->
          <div class="nk-footer">
            <div class="container-fluid">
              <div class="nk-footer-wrap">
                <div class="nk-footer-copyright">
                  &copy; All rights reserved. © PT Portalgo Teknologi Indonesia
                </div>
              </div>
            </div>
          </div>
          <!-- footer @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
import SideBar from "./Sidebar.vue";

export default {
  name: "MainLayout",
  components: {
    SideBar,
  },
  data: () => ({
    user: {},
    version: {},
    setting: {},
  }),

  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.initData();
  },
  methods: {
    doLogout: function () {
      this.$swal({
        title: "Anda akan keluar dari Monitoring Portal Go",
        text: "Lanjut?",
        icon: "warning",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((value) => {
        if (value.isConfirmed) {
          this.$store.dispatch("logout");
          this.$router.push("/");
        }
        return;
      });
    },
    initData: function () {
      this.$axios.get("/version/current").then((res) => {
        this.version = res.data.version;
      });
      this.$axios.get("/setting").then((res) => {
        this.setting = res.data.setting;
      });
    },
    handleCurrentVersion: function (value) {
      this.version = value;
    },

    toggleSidebar: function () {
      var element = document.getElementsByTagName("body");
      var arrow = document.getElementById("arrowLeft");
      var sidebarButton = document.getElementById("sidebarButton");
      element[0].classList.toggle("nav-shown");
      arrow.classList.toggle("nk-sidebar-active toggle-active");
      sidebarButton.classList.toggle("nk-sidebar-mobile nk-sidebar-active");
    },
  },
};
</script>
<style>
@import url("@/../public/assets/css/dashlite.css?ver=3.0.0");
</style>
