import { createApp } from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import router from "./router";
import store from "./store";

import $axios from "./api";
import App from "./App.vue";

import Skeleton from "vue-loading-skeleton";
import "vue-loading-skeleton/dist/style.css";

import VueMobileDetection from "vue-mobile-detection";

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(VueMobileDetection);

app.use(Skeleton);
app.use(VueToast, {
  position: "top-right",
  duration: 1000,
});

app.config.globalProperties.$axios = { ...$axios };

app.mount("#app");
