<template>
  <div>
    <div class="nk-body bg-white npc-general pg-auth">
      <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main">
          <!-- wrap @s -->
          <div class="nk-wrap nk-wrap-nosidebar">
            <!-- content @s -->
            <div class="nk-content">
              <div class="nk-split nk-split-page nk-split-md">
                <div
                  class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white"
                >
                  <div class="nk-block nk-block-middle nk-auth-body">
                    <div class="brand-logo pb-5">
                      <router-link to="/" class="logo-link mb-2">
                        <img
                          class="logo-light logo-img logo-img-lg"
                          :src="setting.logo"
                          alt="logo"
                        />
                        <img
                          class="logo-dark logo-img logo-img-lg"
                          :src="setting.logo"
                          alt="logo-dark"
                        />
                      </router-link>
                      <router-view></router-view>
                    </div>
                  </div>
                  <!-- .nk-block -->
                  <div class="nk-block nk-auth-footer">
                    <div class="mt-3">
                      <p>
                        &copy; All rights reserved. © PT Portalgo Teknologi
                        Indonesia
                      </p>
                    </div>
                  </div>
                  <!-- .nk-block -->
                </div>
                <!-- .nk-split-content -->
                <div
                  v-if="!$isMobile()"
                  class="nk-split-content nk-split-stretch bg-abstract"
                ></div>
                <!-- .nk-split-content -->
              </div>
              <!-- .nk-split -->
            </div>
            <!-- wrap @e -->
          </div>
          <!-- content @e -->
        </div>
        <!-- main @e -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      setting: {},
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    this.$axios.get("/setting").then((res) => {
      this.setting = res.data.setting;
    });
    if (this.isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style>
@import url("@/../public/assets/css/dashlite.css?ver=3.0.0");
</style>
